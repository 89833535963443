import { useState } from "react";
import "./VideoPlay.scss";
import { Col, Modal, Row } from "antd";
// import {VscPlay} from "react-icons/vsc"
// import VideoPlayerPage from '../../pages/VideoPlayerPage/VideoPlayerPage'
const VideoPlay = ({ t }) => {
  const [show, setShow] = useState(false);
  const handelClose = () => setShow(false);
  const handelShow = () => setShow(true);
  return (
    <div className="home_video">
      {/* <div className={show ? "show" : "unshow"}>
        <VideoPlayerPage handelClose={handelClose} />
      </div> */}
      <Modal
        visible={show}
        onCancel={handelClose}
        footer={null}
        className="vedio-modal-wrapper"
        destroyOnClose
      >
        <iframe
          src="//www.youtube.com/embed/AU2Ivgg_28U"
          frameborder="0"
          allowFullScreen
        ></iframe>
      </Modal>
      <div className="video_image" onClick={handelShow}></div>
      <div className="container">
        <Row className="video_content">
          <Col>
            {/* <div className="video_inf">
              <h2 className="video_title">{t.videotitle}</h2> 
              <p>{t.videotitle2}</p>
            </div> */}
            {/* <div className="video_promo_content">
              <Link
                onClick={handelShow}
                hrefLang="https://www.youtube.com/watch?v=AU2Ivgg_28U"
              >
                <span>
                  <BsFillPlayCircleFill />
                </span>
              </Link>
            </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VideoPlay;
