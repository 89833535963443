import { Button, Col, Row } from "antd";
import { Image } from "antd";
import cls from "classnames";
import {} from "../store/reducers/products";

import styles from "../styles/pages/Home.module.scss";

import img_1 from "../public/images/Group 25860.png";
import { useEffect } from "react";
import Api from "../public/network/ApiConfig";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NewBanner from "../components/NewBanner/NewBanner";
import VideoPlay from "../components/VideoPlay/VideoPlay";
import ChauffeurSlider from "../components/Hom_Slider/HomeSlider";
import ServicesSection from "../components/homeSections/services/ServicesSection";
import FeaturesSection from "../components/homeSections/features/FeaturesSection";

const settings = {
  dots: true,
  // infinite: true,
  speed: 800,
  slidesToShow: 2.3,
  slidesToScroll: 1,
  arrows: false,
  // autoplay: true,
  // autoplaySpeed: 3000,
  pauseOnHover: true,
  // centerMode: true,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2.2,
      },
    },

    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.6,
        // centerMode: false,
      },
    },
    // {
    //   breakpoint: 768,
    //   settings: {
    //     slidesToShow: 1.2,
    //     // centerMode: false,
    //   },
    // },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        // centerMode: false,
      },
    },
  ],
};
function Home({ t }) {
  //   const state = useSelector((state) => state);
  //   console.log(state);
  // const dispatch = useDispatch();
  // dispatch();
  const { currentLang, currentUser } = useSelector(
    (state) => state?.entities?.user
  );
  // const [homeData, sethomeData] = useState(null);

  useEffect(() => {
    handelGetCustomerTransactions();
  }, []);

  const handelGetCustomerTransactions = () => {
    if (!currentUser) {
      Api.post("Customers/GuestLogin", {}).then(
        (res) => {
          localStorage.setItem("token", res?.data?.model?.Token?.access_token);
          // Api.get("Home/GetCustomerHome").then(
          //   (res) => {
          //     sethomeData(res?.data?.model);
          //   },
          //   (err) => {
          //     console.log(err);
          //   }
          // );
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      // Api.get("Home/GetCustomerHome").then(
      //   (res) => {
      //     sethomeData(res?.data?.model);
      //   },
      //   (err) => {
      //     console.log(err);
      //   }
      // );
    }
  };

  return (
    <div className={cls("home_page", styles.home_page)}>
      {/* <BannerHero t={t} /> */}
      <NewBanner t={t} />
      <section className={styles.side_by_side}>
        <div className={styles.container}>
          <div className={styles.side_by_side_holder}>
            <Row gutter={[30, 40]}>
              <Col xs={24} lg={12}>
                <div className={styles.side_by_side_image}>
                  <Image src={img_1} alt="img" preview={false} />
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <div className={styles.side_by_side_text}>
                  <h2>
                    <span>{t.about} </span>
                    <span className="color-primary">{t.chauffeur}</span>
                  </h2>
                  <p>{t.GettoknowChauffeur}</p>
                  <Button type="primary">
                    <Link
                      to={{
                        pathname: "/" + currentLang + "/about-us",
                      }}
                      aria-label="learn More"
                    >
                      {t.learnMore}
                    </Link>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <ServicesSection t={t} />
      <FeaturesSection t={t} />
      <VideoPlay t={t} />
      <ChauffeurSlider t={t} />
    </div>
  );
}

export default Home;
