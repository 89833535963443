export default {
  home: "Home",
  ChauffeurTitle:
    "Chauffeur - Your Ultimate Solution for Personalized Shipping and Shopping Services in the UAE.",
  ChauffeurDescription:
    "Chauffeur the speediest all-inclusive Emirati shipping and shopping platform, providing individuals and businesses with professional shipping and shopping services. We guarantee on-time delivery of your products with utmost precision and at the most competitive prices.",
  aboutUs: "about us",
  send: "send",
  NewPassword: "New Password",
  Message: "Message",
  NotFound: "404: Page Not Found",
  NotFoundTitle: "We couldn't find the page you were looking for.",
  NotFoundText: "Here are some options to get back on track:",
  NotFoundText2: "Go back to the homepage",
  NotFoundText3: "Contact us for assistance",
  ComplaintSuggestions: "Complaint & Suggestions",
  Createaccount: "Create account",
  OrSignup: "Or Sign up with ",
  Signup: "Sign up",
  Orlogin: "Or login with ",
  Login: "Login",
  follow: "Follow our news",
  followtitle: "Follow the latest news by subscribing to the messaging service",
  slidertitle1: "What our customers say about Chauffeur",
  slidertitle2:
    "An application that is easy to use, secure, and provides all services quickly And extreme accuracy",
  videotitle:
    "Shauffeur is your professional assistant in all transportation and delivery matters",
  videotitle2:
    "The first Emirati application that provides you with all transportation and delivery services accurately and reliably in the fastest time without effort and at the lowest costs.",
  typeemail: "Type your email",
  thankyou: "Thank You",
  thansuccsess: "Your application was successfully submitted.",
  thancontact: "We'll contact you a decision is made.",
  blogs: "blogs",
  Editprofile: "Edit profile",
  Signin: "Sign in",
  EmailAddress: "Email Address",
  Password: "Password",
  Account: "Account",
  ChargeWallet: "Charge Wallet",
  done: "Done",
  deleteMsg:
    "Deleting your account is permanent and cannot be undone. All your data, will be erased from our systems.",
  settings: "Settings",
  deleteAccount: "Delete Account",
  Pleaseentertransferamount: "Please enter transfer amount",
  transferamount: "transfer amount",
  ChauffeurCardNumber: "Chauffeur Card Number",
  ChauffeurCardNumberwallet:
    "Please enter chauffeur card number to charge your wallet",
  inputChauffeurCardNumber: "Please input your Chauffeur Card Number!",
  SendMoney: "Send Money",
  WriteComment: "Write Comment",
  WriteCancelationReason: "Write Cancelation Reason",
  NoBalancePleaseChargeYourWallet: "No Balance Please Charge Your Wallet",
  Password8characters: "Password must be greater than 8 characters",
  Password20characters: "Password must be less than 20 characters",
  TelephoneNumber: "Telephone Number",
  Phonenumber: "Phone",
  submit: "Submit",
  Amount: "Amount",
  OopsAddress: "Oops.. No Address found!",
  SendVerificationCode: "Send Verification Code",
  Emailvalid: "Email Address is not a valid email",
  twopasswords: "The two passwords that you entered do not match!",
  pleaseselect: "please select ",
  Pleaseinput: "Please input",
  CancelationReason: "Cancelation Reason",
  ReenterPassword: "Re-enter Password",
  changepassword: "Change Password",
  currentpassword: "Current Password",
  newpassword: "New Password",
  Thetwopasswords: "The two passwords that you entered do not match!",
  confirmpassword: "Confirm Password",
  Confirm: "Confirm",
  Forgetpassword: "Forget password ?",
  FullName: "Full Name",
  Verifynow: "Verify now",
  nocompanyfound: "No delivery company found for this shipment.",
  comingsoon: "coming soon",
  contactUs: "Contact us",
  EditProfile: "Edit Profile",
  MyOrders: "My Orders",
  MyAdresses: "My adresses",
  MyWallet: " My Wallet",
  Logout: "Logout",
  Notification: "Notification",
  NoNotification: "No Notification !",
  Language: "Language",
  ReceiverNumber: "Receiver Number",
  SelectReceiver: "Select Receiver",
  searchuser: "search user",
  Delivery: "Delivery",
  Transferto: "Transfer to",
  Searchreceiverphone: "Search receiver phone",
  PersonalShopper: "Personal Shopper",
  footerAboutText: "Chauffeur Brings you Together",
  footerAddress:
    "The 5th Floor of the Business Village Building (B),in  Deira, Office No S84",
  WeAreOnSocialMedia: "We Are On Social Media",
  Shipping: "Shipping",
  RentCar: "Rent car",
  ShareRequirments: "Share requirments",
  TermsAndCondition: "Terms & Condition",
  FAQ: "FAQ",
  ComplaintAndSuggestions: "Complaint & Suggestions",
  Help: "Help",
  Chat: "Chat",
  startchatwithdriver: "start chat with the driver",
  PrivacyPolicy: "Privacy Policy",
  MyProfile: "My Profile",
  CopyrightsReservedFor: "Copyrights Reserved for",
  chauffeurHub: "chauffeur hub",
  curentYear: "2022",
  Company: "Company",
  Profile: "Profile",
  services: "services",
  search: "search",
  startDate: "start date",
  endDate: "end date",
  //
  about: "about",
  chauffeur: "chauffeur",
  learnMore: "learn more",
  features: "features",
  secureData: "Secure Data",
  speedOfUse: "Speed of use",
  easeOfUse: "Ease of Use",
  secureDataDesc:
    "We offer you all the services and solutions that suit your different needs.   ",
  speedOfUseDesc:
    "The delivery and pick-up services are on your mark, just order it.   ",
  easeOfUseDesc: "With just a click, you can easily get all that you need.  ",
  clientsFeedback: "Clients Feedback",

  downloadApp: "Download App",
  ourLocation: "Our Location",
  whyChoose: "Why Choose",
  aSatisfiedCustomerIsBestForBusiness:
    "A Satisfied Customer is best for business",
  team: "Team",
  callUs: "Call Us",
  ourMail: "Our mail",
  contact: "contact",
  weAreOnSocialMedia: "We Are On Social Media",
  us: "us",
  us2: "us",
  Renting: "Renting ",
  termsAndConditions: "terms & conditions",
  visionOf: "Vision of",
  missionOf: "Mission of",
  privacyPolicy: "Privacy Policy",
  privacy: "privacy",
  policy: "Policy",
  signInTo: "Sign in to",
  rememberMe: "Remember Me",
  dontHaveAnAccount: "Don’t have an account ?",
  signUpTo: "Sign up to",
  termsAndConditions2: "Terms and conditions",
  acceptChauffeur: "Accept Chauffeur",

  haveAnAccount: "have an account ?",
  consentToThisItemIsRequired: "Consent to this item is required",
  contactMessage:
    "Enter your account Phone to reset the password. Then you will want to receive a verification code. If you have any problem about password reset plase",
  verificationCode: "Verification Code",

  shipping: "shipping",
  Code: "Code",
  DeleteConfirm:
    "To confirm, please enter your account phone number below. This helps us ensure you're the one deleting your account.",
  myOrders: "my orders",
  DoYouWantToCancelThisOrder: "Do you want to cancel this order?",
  ok: "ok",
  bannerChauffeur:
    "Chauffeur is the first Emirati delivery app that aims to facilitate your life and help you get your work done at the lowest cost and with the utmost speed by providing you with a reliable delivery experience characterized by the highest standards of quality and professionalism and suitable for both individuals and companies.",
  cancel: "cancel",
  Chauffeurapp: "Chauffeur App",
  deliveryService: "Delivery Service",
  GettoknowChauffeur:
    "Chauffeur is your professional assistant and right hand in completing your errands and carrying out pick-up and delivery tasks. Whether you use the App as an Individual or as a Company, we offer you integrated delivery services and practical solutions that meet your different needs starting from delivering small and personal shipments as well as shopping items to transporting goods and heavy machines and equipment all over Emirates. Chauffeur will not let you down at any time as our services are available 24/7. ",
  LE: "AED",
  SAR: "AED",
  from: "from",
  Downloadfrom: "Download from",
  to: "to",
  deliveryInformation: "Delivery information",
  delivery: "delivery",
  orderInfo: "order info",
  deliveryType: "Delivery Type",
  products: "Products",
  deliveryTime: "Delivery Time",
  deliveryNotes: "Delivery Notes",
  deliveryCost: "Delivery Cost",
  VAT: "SERVICE FEES",
  RateThisDelivery: "Rate This Delivery",
  Ratedelivery: "Rate Delivery",
  subTotal: "SubTotal",
  total: "Total",
  Blogs: "Blogs",
  wtsText: "How can I help you?",
  Individual: "Individual",
  Corporate: "Corporate",
  ChauffeurDriver: "Chauffeur",
  EnteryourOrderiD: "Enter your Order ID",
  foundChauffeurlist: "This Number not found in Chauffeur list.",
  trackDelivery: "Track Delivery",
  proceedToPayment: "Proceed To Payment",
  packageSize: "Package Size",
  categoryType: "Category Type",

  doYouWantToDeleteThisAddress: "Do you want to delete this address?",
  myAddresses: "my addresses",
  locationInfo: "Location info",
  addNewAdress: "Add New Adress",
  removeAdress: "Remove Adress",
  orderDelivery: "Order Delivery",
  deliveryCompany: "Delivery Company",
  paymentOwner: "Payment Owner",
  SendSMS: "Send SMS",
  edit: "edit",
  confirm: "Confirm",
  chargeWallet: "charge wallet",
  charge: "Charge",
  wallet: "Wallet",
  quantity: "quantity",
  cardHolderName: "Card holder name",
  AED: "AED",
  chauffeurCard: "Chauffeur Card",
  paymentMethod: "Payment Method",
  selectPayment: "Select Payment",
  method: "Method",
  onlinePayment: "Online Payment",
  deliveryLocation: "Delivery Location",
  package: "package",
  size: "Size",
  type: "type",
  product: "Product",
  name: "name",
  count: "count",
  AppleStore: "Apple Store",
  GooglePlay: "Google Play",
  AppGallery: "App Gallery",
  otherProduct: "other product",
  addProduct: "add",
  startDeliver: "Start Deliver",
  enter: "enter",
  address: "address",
  Asanindividualorcompany:
    "As an individual or a company, Chauffeur offers you reliable pick-up and delivery services at the lowest cost and with no effort",

  category: "category",
  time: "time",
  NotpaidOrder: "Not paid Order",
  paidOrder: "paid Order",
  UploadReceiptImage: "Upload Receipt Image",
  ReceiptImage: "Receipt Image",
  notes: "notes",
  orderName: "order name",
  itemName: "item name",
  list1:
    "We adopt the best quality standards to guarantee you the best service.   ",
  list2:
    "We connect you with the best shipping companies that offer reliable services.  ",
  list3:
    "We listen carefully to you to understand your needs and professionally help you with your problems.  ",
  list4: "Our team receives your inquiries and provides support 24/7.  ",
  GettoknowChauffeur2:
    " We will also come to your aid if your car broke down through the Towing service. With a simple click on Chauffeur App, you can have all the services you need. We work to make you feel safe and untroubled. So, we combined all the services you may need in one place with the highest standards of quality, credibility, and punctuality. Your safety and peace of mind are our main priority in Chauffeur.   ",
  privacyDesc: `We deeply care about your privacy and safety. Therefore, we gather some information to be used via the website in order to offer online services through the website. The website will record and track your use of the website using, including but not limited to, text files and other tracking tools and devices. 
  The use of such information is subject to the terms and conditions of the applicable Privacy Policy. The Privacy Policy is an integral part of the terms and conditions stipulated herein and shall be fully read along with it. In case of your non-acceptance of the terms and conditions of the Privacy Policy, you must stop using Chauffeur website and application. 
  
  We hereby acknowledge our compliance with all privacy protection legislations once issued regarding any personal information we collect. You may be requested to enter information about yourself on different pages of Chauffeur website and application, and we pledge to not use such information for any purpose other than the purposes listed in the Privacy Policy and the purposes determined by each website and application where such information is required. 
  The Privacy Policy governs the way the “Company” collects, uses, keeps, and discloses information collected from the users of the website. This Privacy Policy also applies to the application and all the services and products offered by the Company.
  `,
  bank: "Bank",
};
