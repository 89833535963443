export default {
  home: "الرئيسية",
  ChauffeurTitle: "شوفير - أحصل على أفضل خدمة شحن وتسوق شخصي بالإمارات.",
  ChauffeurDescription:
    "شوفير أسرع منصة شحن إماراتية شاملة، تقدم للأفراد والشركات خدمات شحن وتسوق احترافية؛ لتوصيل منتجاتك في الوقت المطلوب، بكل دقة، وبأفضل سعرًا.",
  aboutUs: "معلومات عنا",
  send: "إرسال",
  follow: "تابع اخبارنا",
  followtitle: "متابعة الجديد عن طريق الاشتراك في خدمة الرسائل",
  typeemail: "اكتب بريدك الالكتروني",
  thankyou: "شكرًا لك",
  thansuccsess: "تم تقديم طلبك بنجاح.",
  thancontact: "سوف نتصل بك ويتم اتخاذ القرار.",
  blogs: "المدونات",
  NewPassword: "كلمة المرور الجديدة",
  Message: "الرسالة",
  NotFound: " الصفحة غير موجودة",
  NotFoundTitle: "لم نتمكن من العثور على الصفحة التي كنت تبحث عنها.",
  NotFoundText: "إليك بعض الخيارات للعودة إلى المسار الصحيح:",
  NotFoundText2: "العودة إلى الصفحة الرئيسية",
  NotFoundText3: "اتصل بنا للمساعدة",
  slidertitle1: "ماذا يقول عملاؤنا عن Chauffeur",
  slidertitle2:
    "تطبيق يتميز بسهولة الاستخدام والأمان وتوفير جميع الخدمات بسرعة ودقة فائقة",
  videotitle: "شوفير مساعدك الاحترافي في جميع أمور النقل والتوصيل",
  videotitle2:
    "اول تطبيق إماراتي يوفر لك جميع خدمات النقل والتوصيل بدقة وموثوقية في أسرع وقت بدون مجهود وبأقل التكاليف.",
  ComplaintSuggestions: "الشكاوي والاقتراحات",
  Createaccount: "إنشاء حساب",
  OrSignup: "أو قم بالتسجيل",
  Signup: "قم بالتسجيل",
  Orlogin: "أو تسجيل الدخول باستخدام",
  Login: " تسجيل الدخول ",
  Editprofile: "تعديل الملف الشخصي",
  Signin: " تسجيل الدخول ",
  EmailAddress: "عنوان البريد الإلكتروني",
  Password: "كلمه المرور",
  Account: "الحساب",
  done: "تم",
  deleteMsg:
    "يعد حذف حسابك أمرًا نهائيًا ولا يمكن التراجع عنه. سيتم مسح جميع بياناتك من أنظمتنا.",
  settings: "الاعدادات",
  deleteAccount: "حذف الحساب",
  AppleStore: "متجر ابل",
  GooglePlay: "متجر جوجل",
  AppGallery: "App Gallery",
  Downloadfrom: "تحميل التطبيق من",
  DeleteConfirm:
    "لنتأكيد حذف حسابك، يرجى إدخال رقم الهاتف المرتبط بحسابك أدناه. سيساعدنا ذلك في ضمان أنك الشخص الذي يقوم بحذف الحساب.",
  Pleaseentertransferamount: "الرجاء إدخال مبلغ التحويل",
  transferamount: " مبلغ التحويل",
  ChauffeurCardNumber: "رقم بطاقة شوفير",
  ChauffeurCardNumberwallet: "الرجاء إدخال رقم بطاقة شوفير لشحن محفظتك",
  inputChauffeurCardNumber: "الرجاء إدخال رقم بطاقة شوفير!",
  ChargeWallet: "شحن المحفظة",
  SendMoney: "ارسال رصيد",
  WriteComment: "اضف تعليقك",
  WriteCancelationReason: "اضف سبب الالغاء",
  NoBalancePleaseChargeYourWallet: "لا يوجد رصيد يرجى شحن محفظتك",
  Password8characters: "يجب أن تكون كلمة المرور أكبر من 8 أحرف",
  Password20characters: "يجب ان تكون كلمة المرور اقل من 20 حرف",
  TelephoneNumber: "رقم هاتف",
  Phonenumber: "رقم الهاتف",
  submit: "إرسال",
  CancelationReason: "سبب الإلغاء",

  RateThisDelivery: "قيم هذا التسليم",
  Ratedelivery: "تقييم التسليم",
  SendVerificationCode: "أرسل رمز التحقق",
  Emailvalid: "عنوان البريد الإلكتروني ليس بريدًا إلكترونيًا صالحًا",
  twopasswords: "كلمتا المرور اللتان أدخلتهما غير متطابقتين!",
  pleaseselect: "الرجاء تحديد ",
  Pleaseinput: "الرجاء ادخال",
  ReenterPassword: "اعادة ادخال كلمة المرور",
  changepassword: "تغيير كلمة المرور",
  currentpassword: "كلمة المرور الحالية",
  newpassword: "كلمة المرور الجديدة",
  Thetwopasswords: "كلمتا المرور اللتان أدخلتهما غير متطابقتين!",
  confirmpassword: "تأكيد كلمة المرور",
  Confirm: "تأكيد ",
  Forgetpassword: "نسيت كلمة المرور ؟ ",
  FullName: "الاسم بالكامل ",
  Verifynow: "تحقق الآن",
  contactUs: "تواصل معنا",
  EditProfile: "تعديل الملف الشخصي",
  MyOrders: "طلباتي",
  MyAdresses: "عناويني",
  MyWallet: "محفظتى",
  Logout: "تسجيل خروج",
  Language: "اللغة",
  Delivery: "التوصيل",
  PersonalShopper: "المتسوق الشخصي",
  services: "خدمات",
  footerAboutText: "شوفير يقربلك المسافات",
  footerAddress:
    "الطابق الخامس من مبنى (قرية الأعمال )B ديرة،   مكتب  رقم   S84",
  WeAreOnSocialMedia: "نحن على وسائل التواصل الاجتماعي",
  Shipping: "شحن",
  RentCar: "تأجير السيارات",
  ShareRequirments: " كيفية المشاركة ",
  TermsAndCondition: "الشروط والأحكام",
  FAQ: "الاسئلة الشائعة",
  ReceiverNumber: "رقم المتلقي",
  ComplaintAndSuggestions: "الشكاوي والاقتراحات",
  Help: "المساعده",
  Transferto: "تحويل إلى",
  SelectReceiver: "حدد المتلقي",
  Chat: "الدردشة",
  searchuser: "البحث عن المستخدم",
  Notification: "إشعارات",
  Searchreceiverphone: "ابحث عن هاتف المتلقي",
  NoNotification: "لا يوجد إشعارات !",
  startchatwithdriver: "ابدأ الدردشة مع السائق",
  PrivacyPolicy: "سياسة الخصوصية",
  MyProfile: "ملفي",
  CopyrightsReservedFor: "حقوق النشر محفوظة لـ",
  chauffeurHub: "شوفير هاب",
  curentYear: "٢٠٢٢",
  Company: "الشركة",
  Profile: "الملف الشخصي",
  search: "بحث",
  startDate: "تاريخ البدء",
  endDate: "تاريخ الانتهاء",
  //
  about: "عن",
  GettoknowChauffeur:
    "شوفير هو مساعدك الاحترافي في عمليات النقل والتوصيل وساعدك الأيمن في إنجاز جميع مهامك. سواء كنت فردًا أو شركة، نتيح لك خيارات توصيل متكاملة ونقدم لك حلولًا عملية تلبي احتياجاتك المختلفة إبتداء من توصيل الشحنات الصغيرة والشخصية وأغراض التسوق وصولاً إلى نقل البضائع والآلات والمعدات الثقيلة، وذلك في كافة مناطق الإمارات. ولن يخذلك شوفير في أي وقت تحتاجه حيث يمكنك طلب الخدمات في أي وقت على مدار 24 ساعة وطوال أيام الأسبوع. ",
  Chauffeurapp: "تطبيق شوفير ",
  bannerChauffeur:
    "شوفير أول تطبيق توصيل إماراتي يعمل لتسهيل حياتك، ومساعدتك في إنجاز أعمالك على أكمل وجه بأقل تكلفة وأقصى سرعة من خلال توفير تجربة نقل وتوصيل موثوقة تتميز بأعلى معايير الجودة والاحترافية ومناسبة للأفراد والشركات",
  chauffeur: "شوفير",
  learnMore: "معرفة المزيد",
  features: "مميزات",
  secureData: "	خدمات متكاملة وحلول عملية",
  speedOfUse: "	السرعة والدقة في المواعيد. ",
  easeOfUse: "	تجربة استخدام سهلة ومميزة. ",

  secureDataDesc:
    "نوفر لك جميع الخدمات والحلول بما يناسب احتياجاتك المختلفة.  ",
  speedOfUseDesc: "رهن إشارتك خدمة النقل والتوصيل، فقط اطلبها لتحصل عليها.   ",
  easeOfUseDesc: "بضغطة زر ستحصل على كل ما تحتاجه وبكل سهولة ويسر.   ",

  clientsFeedback: "ملاحظات العملاء",
  downloadApp: "حمل التطبيق وابدء الاستخدام",
  ourLocation: "موقعنا",
  whyChoose: "لماذا تختار",
  aSatisfiedCustomerIsBestForBusiness:
    "استخدم تطبيق شوفير لأنه أول تطبيق متخصص في خدمات النقل والتوصيل في كافة مناطق الإمارات ",
  team: "فريق",
  call: "اتصل",
  ourMail: "بريدنا",
  contact: "تواصل ",
  weAreOnSocialMedia: "نحن على وسائل التواصل الاجتماعي",
  us: "معنا",
  us2: "!معنا",
  Renting: "تأجير",
  termsAndConditions: "الشروط والاحكام",
  visionOf: "رؤية",
  missionOf: "مهمة",
  privacyPolicy: "سياسة الخصوصية",
  privacy: "الخصوصية",
  policy: "سياسة",
  signInTo: "سجّل الدخول إلى",
  rememberMe: "تذكرنى",
  dontHaveAnAccount: "ليس لديك حساب ؟",
  signUpTo: "التسجيل على",
  termsAndConditions2: "الشروط والاحكام",
  acceptChauffeur: "قبول شوفير",
  haveAnAccount: "لديك حساب؟",
  consentToThisItemIsRequired: "مطلوب الموافقة على هذا البند",
  contactMessage:
    "أدخل الهاتف المرتبط بحسابك لإعادة تعيين كلمة المرور. ثم سترغب في الحصول على رمز التحقق. إذا كان لديك أي مشكلة في إعادة تعيين كلمة المرور تفضل بـال",
  verificationCode: "رمز التحقق",
  shipping: "الشحن",
  myOrders: "طلباتي",
  DoYouWantToCancelThisOrder: "? هل تريد الغاء هذا الاوردر",
  ok: "حسنا",
  cancel: "الغاء",
  deliveryService: "خدمة التوصيل",
  LE: "درهم",
  SAR: "درهم",
  from: "من",
  to: "إلى",
  deliveryInformation: "معلومات التوصيل",
  delivery: "التوصيل",
  orderInfo: "معلومات الطلب",
  deliveryType: "نوع التسليم",
  products: "قائمه المشتريات",
  deliveryTime: "وقت التسليم",
  deliveryNotes: "ملاحظات التسليم",
  deliveryCost: "تكلفة التسليم",
  VAT: "رسوم الخدمه",
  subTotal: "المجموع الفرعي",
  total: "المجموع",
  Blogs: "المدونات",
  wtsText: " كيف يمكنني خدمتك؟",
  Individual: "فردي",
  Corporate: "شركة",
  ChauffeurDriver: "سائق",
  foundChauffeurlist: "لم يتم العثور على هذا الرقم في النظام.",
  trackDelivery: "تتبع التسليم",
  proceedToPayment: "متابعة الدفع",
  packageSize: "حجم العبوة",
  categoryType: "نوع الفئة",
  SendSMS: "أرسل رسالة  ",
  doYouWantToDeleteThisAddress: "هل تريد حذف هذا العنوان؟",
  myAddresses: "عناويني",
  locationInfo: "معلومات الموقع",
  addNewAdress: "اضافة عنوان جديد",
  removeAdress: "إزالة العنوان",
  orderDelivery: "تسليم الطلب",
  deliveryCompany: "شركة التوصيل",
  paymentOwner: "مالك الدفع",
  edit: "تعديل",
  confirm: "تأكيد",
  chargeWallet: "شحن المحفظة",
  charge: "شحن",
  wallet: "المحفظة",
  quantity: "الكمية",
  cardHolderName: "إسم صاحب البطاقة",
  AED: "درهم إماراتي",
  chauffeurCard: "بطاقة Chauffeur",
  bank: "البنك",
  paymentMethod: "طريقة الدفع",
  selectPayment: "اختر الدفع",
  method: "طريقة",
  onlinePayment: "الدفع عبر الإنترنت",
  addProduct: "إضافة ",
  Amount: "المبلغ المستحق",
  OopsAddress: "عفوا .. لم يتم العثور على عنوان!",

  deliveryLocation: "موقع التسليم",
  package: "الحزمة",
  size: "حجم",
  Code: "الرمز",
  type: "نوع",
  product: "المنتج",
  name: "الاسم",
  count: "العدد",
  otherProduct: "منتج آخر",
  startDeliver: "بدء التوصيل",
  enter: "ادخل",
  address: "العنوان",
  category: "الفئة",
  time: "وقت",
  callUs: "اتصل بنا",
  nocompanyfound: "لم يتم العثور على شركة توصيل لهذه الشحنة.",
  Asanindividualorcompany:
    "سواء كنت فردا أو شركة، تطبيق شوفير يوفر لك جميع خدمات النقل والتوصيل بكل موثوقية في أسرع وقت بأقل تكلفة وبدون مجهود",
  comingsoon: "قريبا",
  EnteryourOrderiD: "أدخل رقم طلبك",

  ReceiptImage: " صورة الإيصال",
  notes: "ملحوظات",
  orderName: "اسم الطلب",
  UploadReceiptImage: "تحميل صورة الإيصال",
  itemName: " اسم المنتج",
  NotpaidOrder: "طلب غير مدفوع",
  paidOrder: "طلب مدفوع",
  privacyDesc: `نهتم بخصوصيتك وأمنك بشكل كبير. ويتم جمع بعض البيانات لاستخدامها عن طريق الموقع من أجل توفير الخدمات عن طريق الموقع عبر الإنترنت، وسوف يقوم الموقع بتسجيل ومتابعة استخدامك للموقع من خلال استخدام، على سبيل المثال لا الحصر، الملفات النصية وغيرها من أدوات وأجهزة المتابعة.
  يخضع استخدام هذه البيانات لبنود سياسة الخصوصية التي نطبقها. وتعتبر سياسية الخصوصية جزءًا من هذه الشروط والأحكام ويجب أن تقرأها بالكامل. وفي حال عدم قبولك الشروط في سياسة الخصوصية، يجب عليك أن تمتنع عن استخدام موقع وتطبيق شوفير.
  
  نقر بالتزامنا بكافة تشريعات حماية الخصوصية وقت صدورها فيما يتعلق بأي معلومات شخصية تتعلق بك قمنا نحن بجمعها، وقد يطلب منك أن تدخل معلومات عن نفسك على صفحات مختلفة على موقع وتطبيق شوفير،  ونحن نلتزم بعدم استخدام هذه المعلومات التي زودتنا بها لأي غرض لم يتم إدراجه في سياسة الخصوصية ولغير الغرض الذي يحدده كل موقع وتطبيق حيث يتم طلب مثل هذه المعلومات.
  تحكم سياسة الخصوصية الأسلوب الذي تقوم به " الشركة" بجمع المعلومات واستخدامها والحفاظ عليها والتصريح بالمعلومات التي تم جمعها من قبل مستخدمي الموقع وتطبق سياسة الخصوصية هذه في التطبيق وكافة المنتجات والخدمات المقدمة من الشركة.
  `,
  list1: "	نعتمد أفضل معايير الجودة ونضمن حصولك على خدمات متميزة.  ",
  list2: "	نصلك بأفضل شركات شحن توفر لك خدمات موثوقة.   ",
  list3: "	نصغي إليك ونتفهم احتياجاتك ونساعدك على حل مشكلاتك باحترافية.  ",
  list4: "	فريقنا يستقبل استفساراتكم ويوفر لكم الدعم على مدار الساعة.",
  GettoknowChauffeur2:
    "كما سنهب لمساعدتك إذا تعطلت سيارتك على الطريق من خلال خدمة قطر السيارات المعطلة. وبهذا تكون كل الخدمات التي تحتاجها على بعد ضغطة زر على تطبيق شوفير. إضافة إلى شعورك بالأمان وراحة البال لأن شوفير يوفر كل الخدمات التي قد تحتاجها في مكان واحد وبأعلى معايير الجودة والمصداقية والدقة في المواعيد؛ فأمانك وراحتك أولويتنا في شوفير.  ",
};
