import React from "react";
import "./FooterHero.scss";
import { Button, Col, Form, Input, Row, message } from "antd";
// import { SendOutlined } from '@ant-design/icons'
import { RiSendPlaneFill } from "react-icons/ri";
import Api from "../../public/network/ApiConfig";
import { useForm } from "antd/lib/form/Form";
const FooterHero = ({ t }) => {
  const [form] = Form.useForm();

  const handelSendSubscripe = (values) => {
    console.log(values, "sdfa");
    Api.post("Users/AddNewsLettersSubscription", values).then(
      (res) => {
        message.success(res?.data?.metas?.message);
        form?.resetFields();
      },
      (err) => {
        message.error(err?.message);
      }
    );
  };

  return (
    <>
      <div className="footer_hero">
        <div className="container">
          <Row className="footer_info">
            <Col>
              <div className="footer_content">
                <h3>{t.follow}</h3>
                <p>{t.followtitle}</p>
              </div>
            </Col>
            <Col>
              <Form
                form={form}
                className="footer_form"
                onFinish={handelSendSubscripe}
              >
                <Form.Item
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: `${t.Pleaseinput} ${t.EmailAddress}`,
                    },
                    { type: "email", message: `${t.Emailvalid}` },
                  ]}
                  className="input-holder"
                >
                  <Input placeholder={t.EmailAddress} />
                </Form.Item>
                <Button
                  className="form_btn"
                  aria-label="submit form"
                  icon={<RiSendPlaneFill />}
                  htmlType="submit"
                />
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FooterHero;
