import { useEffect, useState } from "react";
import { Image } from "antd";
import { Link, useHistory } from "react-router-dom";
import styles from "./Header.module.scss";
import { Avatar, Badge, Button, Dropdown, Menu, message } from "antd";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import cls from "classnames";

import { BiChevronDown } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentLang,
  setCurrentUser,
  setCustomerAddresses,
  setServicesData,
} from "../../store/reducers/user";
import Api from "../../public/network/ApiConfig";
import { setTransferMoney, setUserOrder } from "../../store/reducers/carts";
import img_1 from "../../public/images/Group 23582.svg";
import img_2 from "../../public/images/user, account, people, man.png";
import img_3 from "../../public/images/translating.svg";
import img_4 from "../../public/images/Notification.svg";
import img_5 from "../../public/images/search-interface-symbol.svg";
import img_en from "../../public/images/en.png";
import img_ar from "../../public/images/ar.png";
import edit_img from "../../public/images/edit.svg";
import order_img from "../../public/images/order.svg";
import address_img from "../../public/images/address.svg";
import wallet_img from "../../public/images/wallet.svg";
import logout_img from "../../public/images/logout.svg";
import { UserOutlined } from "@ant-design/icons";

import OwnModal from "../OwnModal/OwnModal";
import SearchHero from "../SearchHero/SearchHero";

const Header = ({ t }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const { currentUser, currentLang, servicesList } = useSelector(
    (state) => state?.entities?.user
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const [NotificationCount, setNotificationCount] = useState(null);
  // const [listData, setListData] = useState([]);
  useEffect(() => {
    if (currentUser) {
      handelGetNotificationCount();
    }
  }, [currentUser]);

  const handelGetNotificationCount = () => {
    Api.get("Users/GetNotificationNotSeenCount").then(
      (res) => {
        setNotificationCount(res?.data?.model);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    !servicesList?.length &&
      Api.get("MetaData/GetServices").then(
        (res) => {
          // console.log(res?.data?.model, "services");
          // setListData(res?.data?.model || []);
          dispatch(setServicesData(res?.data?.model));
        },
        (err) => {
          console.log(err);
        }
      );
  }, []);

  // const { locale } = router;
  // const t = locale === "en" ? en : ar;
  // const chanageLang = (lang) => {
  //   history.push("/", "/", { locale: lang });
  // };
  const handelUSerMenu = ({ key }) => {
    console.log(key);
    if (+key === 1) {
      history.push({
        pathname: "/" + currentLang + "/profile",
      });
    }
    if (+key === 2) {
      history.push({
        pathname: "/" + currentLang + "/orders",
      });
    }
    if (+key === 3) {
      history.push({
        pathname: "/" + currentLang + "/address",
      });
    }
    if (+key === 4) {
      history.push({
        pathname: "/" + currentLang + "/my-wallet",
      });
    }
    if (+key === 5) {
      dispatch(setCurrentUser(null));
      dispatch(setCustomerAddresses(null));
      dispatch(setUserOrder(null));
      dispatch(setTransferMoney(null));
      localStorage.removeItem("token");
      history.push({
        pathname: "/",
      });
      return false;
      Api.get("Customers/CustomerLogout").then(
        (res) => {
          message.success(res?.data?.metas?.message);
          dispatch(setCurrentUser(null));
          dispatch(setCustomerAddresses(null));
          dispatch(setUserOrder(null));
          dispatch(setTransferMoney(null));
          localStorage.removeItem("token");
          history.push({
            pathname: "/",
          });
        },
        (err) => {
          console.log(err);
        }
      );
      // dispatch(setCurrentUser(null))
      //   history.push({
      //     pathname: '/address',
      // })
    }
  };

  const chanageLang = ({ key }) => {
    dispatch(setCurrentLang(key));
    document.getElementsByTagName("html")[0].setAttribute("lang", key);
  };

  const servicesMenu = (
    <Menu>
      {servicesList?.map(
        (s) =>
          s?.Enabled && (
            <Menu.Item key={s?.Id}>
              <Link
                to={{
                  pathname: "/" + currentLang + "/order-delivery",
                  state: { serviceId: s?.Id },
                }}
                //  to={`/order-delivery/${s?.Id}`}
                rel="noopener noreferrer"
              >
                {currentLang === "en" ? s?.Name : s?.NameLT}
              </Link>
            </Menu.Item>
          )
      )}
      {/* <Menu.Item key="1">
        <Link to="/order-delivery" rel="noopener noreferrer">
          {t.Delivery}
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/order-delivery" rel="noopener noreferrer">
          {t.PersonalShopper}
        </Link>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <>
      {/* <TopHeader /> */}
      <div className={styles.header_wrapper}>
        <div className="container">
          <div className={styles.header_holder}>
            <div className={styles.brand}>
              <div className={styles.brand_logo}>
                <Link to="/" title="Chauffeur">
                  <Image src={img_1} alt="chauffeur logo" preview={false} />
                </Link>
              </div>
            </div>
            <nav
              className={`${styles.nav} ${isMenuVisible ? styles.active : ""}`}
            >
              <ul>
                <li>
                  <Link to="/">{t.home}</Link>
                </li>
                <li>
                  <Link to={"/" + currentLang + "/about-us"}>{t.aboutUs}</Link>
                </li>
                <li>
                  <Dropdown
                    overlay={servicesMenu}
                    arrow={{ pointAtCenter: true }}
                    trigger={["click", "hover"]}
                  >
                    <p
                      className={styles.link_with_arrow}
                      onClick={(e) => e.preventDefault()}
                    >
                      {t.services}
                      <BiChevronDown />
                    </p>
                  </Dropdown>
                </li>
                <li>
                  <Link to={"/" + currentLang + "/blogs"}>{t.Blogs}</Link>
                </li>
                <li>
                  <Link to={"/" + currentLang + "/contact-us"}>
                    {t.contactUs}
                  </Link>
                </li>
              </ul>
            </nav>

            {/* <div className={styles.phone_num}>
              <a href="tel:+880 174 792">
                <div className={styles.phone_num_logo}>
                  <Image
                    src="/images/Calling.svg"
                    alt="phone logo"
                    width={30}
                    height={25}
                  />
                </div>
                <span>+880 174 792</span>
              </a>
            </div> */}

            <div className={styles.user_actions}>
              {!currentUser ? (
                <Link to={"/" + currentLang + "/login"} className="res-hidden">
                  <Button type="link" className={styles.user_actions_btn}>
                    <div className={styles.user_actions_logo}>
                      {/* <Avatar size={24} 
                icon={<UserOutlined />}

                /> */}
                      <Image src={img_2} alt="user logo" preview={false} />
                    </div>
                    <span className={styles.label_text}>{t.Login}</span>
                  </Button>
                </Link>
              ) : (
                <Dropdown
                  arrow={{ pointAtCenter: true }}
                  trigger={["click", "hover"]}
                  overlay={
                    <Menu
                      className={cls(
                        "user-dropdown-holder",
                        styles.user_drop_menu
                      )}
                      onClick={handelUSerMenu}
                    >
                      <Menu.Item
                        key="1"
                        icon={
                          <Image src={edit_img} alt="img" preview={false} />
                        }
                      >
                        {t.EditProfile}
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        icon={
                          <Image src={order_img} alt="img" preview={false} />
                        }
                      >
                        {t.MyOrders}
                      </Menu.Item>
                      <Menu.Item
                        key="3"
                        icon={
                          <Image src={address_img} alt="img" preview={false} />
                        }
                      >
                        {t.MyAdresses}
                      </Menu.Item>
                      <Menu.Item
                        key="4"
                        icon={
                          <Image src={wallet_img} alt="img" preview={false} />
                        }
                      >
                        {t.MyWallet}
                      </Menu.Item>
                      <Menu.Item
                        key="5"
                        icon={
                          <Image src={logout_img} alt="img" preview={false} />
                        }
                      >
                        {t.Logout}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    type="link"
                    className={styles.user_actions_btn + " res-hidden"}
                  >
                    <div className={styles.user_actions_logo}>
                      {/* {currentUser?.ProfileImage && ( */}
                      <Avatar
                        size={28}
                        icon={<UserOutlined />}
                        src={currentUser?.ProfileImage}
                      />
                      {/* // )} */}
                    </div>
                    <span className={styles.label_text}>
                      {currentUser?.FullName}
                    </span>
                  </Button>
                </Dropdown>
              )}

              <Dropdown
                arrow={{ pointAtCenter: true }}
                trigger={["click", "hover"]}
                overlay={
                  <Menu
                    className={cls(
                      "user-dropdown-holder",
                      styles.user_drop_menu
                    )}
                    onClick={chanageLang}
                  >
                    <Menu.Item
                      key="en"
                      icon={
                        <Image
                          src={img_en}
                          width={15}
                          alt="img"
                          preview={false}
                        />
                      }
                    >
                      English
                    </Menu.Item>
                    <Menu.Item
                      key="ar"
                      icon={
                        <Image
                          src={img_ar}
                          width={17}
                          alt="img"
                          preview={false}
                        />
                      }
                    >
                      العربيه
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="link" className={styles.user_actions_btn}>
                  <div className={styles.user_actions_logo}>
                    <Image src={img_3} alt="img" preview={false} />
                  </div>
                  <span className={styles.label_text}>{t.Language}</span>
                </Button>
              </Dropdown>

              <Link
                to={"/" + currentLang + "/notification"}
                type="link"
                className={styles.user_actions_btn}
              >
                <Badge count={NotificationCount}>
                  <div className={styles.user_actions_logo}>
                    <Image src={img_4} alt="img" preview={false} />
                  </div>
                </Badge>
              </Link>
              <Button
                type="link"
                className={styles.user_actions_btn + " res-hidden"}
                onClick={() => setIsSearchModalVisible(true)}
              >
                <div className={styles.user_actions_logo}>
                  <Image src={img_5} alt="img" preview={false} />
                </div>
              </Button>
            </div>
            <Button
              type="link"
              className={styles.btn_menu_toggle}
              onClick={() => setIsMenuVisible(!isMenuVisible)}
            >
              {isMenuVisible ? <RiMenuFoldLine /> : <RiMenuUnfoldLine />}
            </Button>
          </div>
        </div>
      </div>
      <OwnModal
        isModalVisible={isSearchModalVisible}
        handleCancel={() => setIsSearchModalVisible(false)}
        width="90%"
        onCancel={() => setIsSearchModalVisible(false)}
      >
        <SearchHero btnText={t.search} />
      </OwnModal>
    </>
  );
};

export default Header;
