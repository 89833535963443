import { Spin } from "antd";
import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

// Import lazy-loaded components
// const Home = React.lazy(() => import("./pages"));
import Home from "./pages";
import NotFound from "./pages/NotFound";
const AboutUs = React.lazy(() => import("./pages/about-us"));
const FAQ = React.lazy(() => import("./pages/FAQ"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacy-policy"));
const Complaint = React.lazy(() => import("./pages/complaint-suggestions"));
const Forgetpassword = React.lazy(() => import("./pages/forgetpassword"));
const Login = React.lazy(() => import("./pages/login"));
const Verification = React.lazy(() => import("./pages/verification"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Signup = React.lazy(() => import("./pages/signup"));
const TermsAndconditions = React.lazy(() => import("./pages/terms&conditions"));
const Profile = React.lazy(() => import("./pages/profile"));
const ResetPassword = React.lazy(() => import("./pages/resetPassword"));
const Shipping = React.lazy(() => import("./pages/shipping"));
const Orders = React.lazy(() => import("./pages/orders"));
const OrderDetails = React.lazy(() => import("./pages/orders/SingleOrder"));
const MyAddresses = React.lazy(() => import("./pages/address"));
const ChargeWalletType = React.lazy(() => import("./pages/charge-wallet-type"));
const ChargeWallet = React.lazy(() => import("./pages/charge-wallet"));
const ContactUs = React.lazy(() => import("./pages/contact-us"));
const EnterChauffeurCardNumber = React.lazy(() =>
  import("./pages/enter-chauffeur-card-number")
);
const MyWallet = React.lazy(() => import("./pages/my-wallet"));
const Notification = React.lazy(() => import("./pages/notification"));
const SearchUser = React.lazy(() => import("./pages/search-user"));
const SelectReceiver = React.lazy(() => import("./pages/select-receiver"));
const SendMoney = React.lazy(() => import("./pages/send-money"));
const OrderInfo = React.lazy(() => import("./pages/OrderInfo/OrderInfo"));
const TrackDelivery = React.lazy(() =>
  import("./pages/TrackDelivery/TrackDelivery")
);
const PaymentMethod = React.lazy(() => import("./pages/Payment-method"));
const HelpChat = React.lazy(() => import("./pages/HelpChat/HelpChat"));
const ThankYou = React.lazy(() => import("./components/ThankYou/ThankYou"));
const BlogsSingelPage = React.lazy(() =>
  import("./components/BlogsSingelPage/BlogsSingelPage")
);
const Blogs = React.lazy(() => import("./pages/Blogs/Blogs"));
const OrderDelivery = React.lazy(() => import("./pages/order-delivery"));
const SelectDeliveryType = React.lazy(() =>
  import("./pages/shipping/select-delivery-type")
);
const SelectOrderType = React.lazy(() =>
  import("./pages/shipping/select-order-type")
);
// import OrderDelivery from "./pages/order-delivery";
// const base = '/:locale(en|ar)?';
// console.log(base)

class Routes extends Component {
  render() {
    const { t } = this.props;

    return (
      <Suspense
        fallback={
          <div className="spin-wrapper">
            <Spin
              spinning
              indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
            />
          </div>
        }
      >
        <Switch>
          <Route
            exact
            path={":lang?/"}
            render={(props) => <Home {...props} t={t} />}
          />

          <Route
            exact
            path={"/:lang?/about-us"}
            render={(props) => <AboutUs {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/faq"}
            render={(props) => <FAQ {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/contact-us"}
            render={(props) => <ContactUs {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/terms-conditions"}
            render={(props) => <TermsAndconditions {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/blogs"}
            render={(props) => <Blogs {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/blogs/:id"}
            render={(props) => <BlogsSingelPage {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/thank-you"}
            render={(props) => <ThankYou {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/privacy-policy"}
            render={(props) => <PrivacyPolicy {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/complaint-suggestions"}
            render={(props) => <Complaint {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/login"}
            render={(props) => <Login {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/signup"}
            render={(props) => <Signup {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/forgetpassword"}
            render={(props) => <Forgetpassword {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/resetPassword"}
            render={(props) => <ResetPassword {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/verification"}
            render={(props) => <Verification {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/account-settings/delete-account"}
            render={(props) => <Settings {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/profile"}
            render={(props) => <Profile {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/shipping"}
            render={(props) => <Shipping {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/orders"}
            render={(props) => <Orders {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/orders/:id"}
            render={(props) => <OrderDetails {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/address"}
            render={(props) => <MyAddresses {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/order-info"}
            render={(props) => <OrderInfo {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/charge-wallet"}
            render={(props) => <ChargeWallet {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/charge-wallet-type"}
            render={(props) => <ChargeWalletType {...props} t={t} />}
          />

          <Route
            exact
            path={"/:lang?/order-payment"}
            render={(props) => <PaymentMethod {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/order-delivery"}
            render={(props) => <OrderDelivery {...props} t={t} />}
          />
          {/* st */}
          <Route
            exact
            path={"/:lang?/enter-chauffeur-card-number"}
            render={(props) => <EnterChauffeurCardNumber {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/my-wallet"}
            render={(props) => <MyWallet {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/notification"}
            render={(props) => <Notification {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/send-money"}
            render={(props) => <SendMoney {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/select-receiver"}
            render={(props) => <SelectReceiver {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/search-user"}
            render={(props) => <SearchUser {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/shipping/select-delivery-type"}
            render={(props) => <SelectDeliveryType {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/select-order-type"}
            render={(props) => <SelectOrderType {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/track-delivery/:id"}
            render={(props) => <TrackDelivery {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/help"}
            render={(props) => <HelpChat {...props} t={t} />}
          />
          <Route
            exact
            path={"/:lang?/chat/:OrderId/:partnerId"}
            render={(props) => <HelpChat {...props} t={t} />}
          />
          <Route
            path="*"
            render={(props) => <NotFound {...props} t={t} />}
          />
          {/* <Route path="*" exact element={<NotFound />} t={t} /> */}
          {/* <Route path="*" element={<NotFound />} t={t} /> */}
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
